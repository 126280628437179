import { EColumnType, EPlaceholderType } from "~/@types/localTypes/table";

export enum EItemLineConfig {
  BANKS = "banks",
  SALES = "sales",
  RECOVERY = "recovery",
  ACCRUALS = "accruals",
  PURCHASES = "purchases",
  BILLING = "billing",
  PRODUCTS = "products",
  EMPLOYEES = "employees",
  TIERS = "tiers",
  SETTINGS = "settings",
  ZTICKETS = "zTickets",
  SOCIETY_LIST = "societyList",
  LIBRARY = "library",
  ADMIN_USER_LIST = "adminUserList",
  ASSOCIATE_CURRENT_ACCOUNT = "associateCurrentAccount",
  AUXILIARY_LEDGER_ENTRIES = "auxiliaryLedgerEntries",
  VAT = "vat",
  DAS = "das2",
  PAYMENTS = "payments",
  CHART_ACCOUNT = "chartAccount",
  COMPANY_TAX_ADVANCE = "companyTaxAdvances",
  BALANCE_SHEET = "balanceSheet",
  PRE_COMPTA = "preCompta",
  BALANCE_SHEET_DOCUMENTS = "balanceSheetDocuments",
  ACCOUNT_SPE = "specificAccountingPlan",
  ACCOUNT_PERSO = "persoAccountingPlan",
  BANK_ACCOUNTS = "bankAccounts",
  BANK_SYNCHRO = "bankSynchro",
  GENERIC_ACCOUNT = "genericAccount",
  REVISION_ACCOUNT = "revisionAccount",
  REVISION_ACCOUNT_CHILD = "revisionAccountChild",
  EXPERT_ACCOUNTANT = "expertAccountant",
  EXPERT_ACCOUNTANT_ACTIVE_LINE = "expertAccountantActiveLine",
}

type RestArgsConfig = {
  [x: string]: boolean | string;
};

const udsColors = getUdsColors();

export function useItemLinesComposable() {
  /**
   * @param {number=}  [cellsWorkableWidth] - number param.
   * @param {RestArgsConfig[]}  [args] - A args param, recovers the remaining parameters as needed.
   * @returns returns the config object
   */
  function getItemLineConfig(cellsWorkableWidth?: number, ...args: RestArgsConfig[]) {
    const itemLinesConfig = {
      banks: [
        {
          width: 308,
          placeholderWidth: 118,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: "empty",
          shouldDisplay: cellsWorkableWidth! >= 990 + (args?.[0]?.isExpenseNotesTab ? 170 : 0),
          type: EColumnType.SPE_TAG,
        },
        ...(args?.[0]?.isExpenseNotesTab
          ? [
              {
                width: 170,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 900,
                type: EColumnType.SPE_TEXT,
              },
            ]
          : []),
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "center",
          shouldDisplay: cellsWorkableWidth! >= 645 + (args?.[0]?.isExpenseNotesTab ? 170 : 0),
          type: EColumnType.DATE,
          path: "dateValue",
          dateFormat: `${EDateFormat.DIGIT_DAY} ${EDateFormat.FULL_MONTH}`,
        },
        ...(!args?.[0]?.isNoTva && !args?.[0]?.isAccruals
          ? [
              {
                width: 200,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                justifyContent: "end",
                shouldDisplay:
                  cellsWorkableWidth! >= 845 + (args?.[0]?.isExpenseNotesTab ? 170 : 0),
                type: EColumnType.SPE_VAT,
              },
            ]
          : []),
        {
          width: 172,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 490,
          path: "amount",
          type: EColumnType.AMOUNT,
          useColor: true,
        },
      ],
      sales: [
        {
          width: 330,
          placeholderWidth: 138,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        ...(args?.[0]?.isAccruals && args?.[0]?.isInvoiceTab
          ? [
              {
                width: 150,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 1235,
                type: EColumnType.SPE_TAG,
              },
            ]
          : []),
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 730,
          type: EColumnType.DATE,
          path: args?.[0]?.isAccruals && args?.[0]?.isInvoiceTab ? "dateValue" : "emissionDate",
          dateFormat: `${EDateFormat.DIGIT_DAY} ${EDateFormat.FULL_MONTH}`,
        },
        ...(args?.[0]?.isAccruals && args?.[0]?.isInvoiceTab
          ? [
              {
                width: 230,
                placeholderWidth: 135,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 1085,
                type: EColumnType.SPE_TEXT,
              },
            ]
          : []),
        {
          width: 125,
          placeholderWidth: 100,
          placeholderType: !args?.[0]?.isDraftTab ? "simple" : "empty",
          shouldDisplay: cellsWorkableWidth! >= 855,
          type: EColumnType.SPE_STATUS,
        },
        {
          width: 180,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.DOUBLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 580,
          type: EColumnType.SPE_VAT,
          path: "file.createdAt",
        },
      ],
      recovery: [
        {
          width: 410,
          placeholderWidth: 138,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          width: 130,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 1000,
          type: EColumnType.DATE,
          path: "createdAt",
          dateFormat: `${EDateFormat.DIGIT_DAY} ${EDateFormat.FULL_MONTH}`,
        },
        {
          width: 260,
          placeholderWidth: 100,
          placeholderType: "status",
          shouldDisplay: cellsWorkableWidth! >= 870,
          type: EColumnType.SPE_TAG,
        },
        {
          width: 200,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.DOUBLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 610,
          type: EColumnType.SPE_VAT,
        },
      ],
      accruals: [
        {
          width: 300,
          placeholderWidth: 148,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
        },
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 1050 + (args?.[0]?.isExpenseNotesTab ? 150 : 0),
        },
        ...(args?.[0]?.isExpenseNotesTab
          ? [
              {
                width: 170,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 900,
              },
            ]
          : []),
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "center",
          shouldDisplay: cellsWorkableWidth! >= 900 + (args?.[0]?.isExpenseNotesTab ? 150 : 0),
        },
        {
          width: 230,
          placeholderWidth: 134,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 730,
        },
        {
          width: 200,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.DOUBLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 500,
        },
      ],
      purchases: [
        {
          width: 300,
          placeholderWidth: 128,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 630,
          type: EColumnType.DATE,
          path: "emissionDate",
          dateFormat: `${EDateFormat.DIGIT_DAY} ${EDateFormat.FULL_MONTH}`,
        },
        {
          width: 230,
          placeholderWidth: 185,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 530,
          type: EColumnType.DATE_TEXT,
          path: "file.createdAt",
          dateFormat: `${EDateFormat.DIGIT_DAY}/${EDateFormat.DIGIT_MONTH}`,
          displayHours: true,
        },
      ],
      billing: [
        {
          width: 130,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: true,
        },
        {
          width: 90,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: true,
        },
        {
          width: 100,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: true,
        },
        {
          width: 200,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: true,
        },
        {
          width: 130,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: true,
        },
        {
          width: 30,
          placeholderWidth: 30,
          placeholderType: "empty",
          shouldDisplay: true,
        },
      ],
      products: [
        {
          width: 338,
          placeholderWidth: 118,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          width: 200,
          placeholderWidth: 103,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 538,
          type: EColumnType.TEXT,
          path: "comment",
          emptyText: "Commentaire",
        },
        {
          width: 107,
          placeholderWidth: 73,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 645,
          type: EColumnType.VAT,
          path: "idVat",
          data: "full",
        },
        {
          width: 120,
          placeholderWidth: 63,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 765,
          type: EColumnType.SPE_TAG,
        },
        {
          width: 200,
          placeholderWidth: 92,
          placeholderType: EPlaceholderType.DOUBLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 965,
          type: EColumnType.VAT_AMOUNT,
          path: "priceTaxExcluded",
          data: "vat",
        },
      ],
      employees: [
        {
          label: "Nom",
          width: args?.[0]?.isPayrollTab ? 150 : 135,
          placeholderWidth: 110,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.TEXT,
          path: args[0]?.isPayrollTab ? "employee.lastname" : "lastname",
          flex: "start",
        },
        {
          label: "Prénom",
          width: args?.[0]?.isPayrollTab ? 150 : 135,
          placeholderWidth: 110,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 420,
          type: EColumnType.TEXT,
          path: args[0]?.isPayrollTab ? "employee.firstname" : "firstname",
          flex: "start",
        },
        ...(args?.[0]?.isPayrollTab
          ? [
              {
                label: "Heures contrat",
                width: 110,
                placeholderWidth: 80,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 530,
                type: EColumnType.TEXT,
                path: "monthlyHours",
                flex: "start",
              },
              {
                label: "CP",
                width: 90,
                placeholderWidth: 50,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 610,
                type: EColumnType.SPE_AMOUNT,
                path: "CP",
              },
              {
                label: "RTT",
                width: 90,
                placeholderWidth: 50,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 700,
                type: EColumnType.SPE_AMOUNT,
                path: "RTT",
              },
              {
                label: "AM",
                width: 90,
                placeholderWidth: 50,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 790,
                type: EColumnType.SPE_AMOUNT,
                path: "AM",
              },
            ]
          : [
              {
                label: "Salaire brut",
                width: 120,
                placeholderWidth: 100,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 520,
                type: EColumnType.AMOUNT,
                path: "salary",
                hasSymbol: false,
              },
              {
                label: "Poste",
                width: 155,
                placeholderWidth: 110,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 820,
                type: EColumnType.TEXT,
                path: "poste",
                flex: "start",
              },
              {
                label: "Contrat",
                width: 145,
                placeholderWidth: 110,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 665,
                type: EColumnType.TEXT,
                path: "typeContrat.name",
                flex: "start",
              },
            ]),
        {
          label: "Actions",
          width: args?.[0]?.isPayrollTab ? 120 : 130,
          placeholderWidth: 90,
          placeholderType: "empty",
          shouldDisplay: cellsWorkableWidth! >= 270,
          type: EColumnType.ACTION,
        },
      ],
      tiers: [
        {
          width: 338,
          placeholderWidth: 118,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "center",
          shouldDisplay: cellsWorkableWidth! >= 990,
          type: EColumnType.TEXT,
          path: "fullname",
          emptyText: "Aucun interlocuteur",
        },
        {
          width: 250,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "center",
          shouldDisplay: cellsWorkableWidth! >= 645,
          type: EColumnType.TEXT,
          path: "phone",
          emptyText: "Aucun numéro de téléphone",
        },
        {
          width: 200,
          placeholderWidth: 155,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 845,
          type: EColumnType.TEXT,
          path: "email",
          emptyText: "Aucune adresse mail",
        },
      ],
      settings: [
        {
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: true,
        },
        {
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: true,
        },
        {
          width: 150,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: true,
        },
        {
          width: 150,
          placeholderWidth: 150,
          placeholderType: "empty",
          shouldDisplay: true,
        },
      ],
      zTickets: [
        {
          width: 308,
          placeholderWidth: 250,
          placeholderType: EPlaceholderType.SQUIRCLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.SQUIRCLE_TEXT,
          path: "file.name",
          iconName: "DocumentPurchaseInvoice",
          iconColor: udsColors.udsError500,
        },
        {
          width: 300,
          placeholderWidth: 250,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 608,
          type: EColumnType.DATE_TEXT,
          path: "createdAt",
          dateFormat: `${EDateFormat.DIGIT_DAY} ${EDateFormat.FULL_MONTH} ${EDateFormat.FULL_YEAR}`,
          displayHours: false,
        },
        {
          width: 172,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.DOUBLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 780,
          type: EColumnType.SPE_AMOUNT,
        },
      ],
      societyList: [
        {
          label: "#",
          width: 100,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.TEXT,
          path: "id",
        },
        {
          label: "Inscription",
          width: 150,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 830,
          type: EColumnType.DATE,
          path: "createdAt",
          dateFormat: `${EDateFormat.DAY_MONTH_YEAR_SLASH} HH:mm`,
        },
        {
          label: "Raison sociale",
          width: 150,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 390,
          type: EColumnType.TEXT,
          path: "name",
          emptyText: "Non défini",
        },
        {
          label: "Nom",
          width: 150,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 540,
          type: EColumnType.SPE_TEXT,
        },
        {
          label: "DIA",
          width: 80,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 950,
          type: EColumnType.TEXT,
          path: "diaCode",
        },
        {
          label: "TVA",
          width: 40,
          placeholderWidth: 20,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 810,
          type: EColumnType.SPE_VAT,
        },
        {
          label: "Comptabilité",
          width: 100,
          placeholderWidth: 140,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 900,
          type: EColumnType.SPE_TAG,
        },
        {
          label: "Forfait",
          width: 80,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 990,
          type: EColumnType.SPE_STATUS,
        },
        {
          label: "Actions",
          width: 200,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.ACTION,
        },
      ],
      adminUserList: [
        {
          label: "Identifiant",
          width: 100,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: true,
          type: EColumnType.MAIN,
        },
        {
          label: "Login",
          width: 200,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 700,
          type: EColumnType.TEXT,
          path: "email",
        },
        {
          label: "Email",
          width: 150,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 890,
          type: EColumnType.TEXT,
          path: "email",
        },
        {
          label: "Prénom",
          width: 130,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 500,
          type: EColumnType.TEXT,
          path: "firstname",
        },
        {
          label: "Nom",
          width: 130,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 370,
          type: EColumnType.TEXT,
          path: "lastname",
        },
        {
          label: "Actif",
          width: 40,
          placeholderWidth: 20,
          placeholderType: EPlaceholderType.DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 840,
          type: EColumnType.SPE_STATUS,
        },
        {
          label: "Action",
          width: 140,
          placeholderWidth: 180,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 240,
          path: "action",
        },
      ],
      library: [
        {
          width:
            cellsWorkableWidth && cellsWorkableWidth - 500 > 300 ? cellsWorkableWidth - 500 : 300,
          placeholderWidth: 148,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.SQUIRCLE_TEXT,
          path: "fileFromGed.name",
          emptyText: "Dénomination",
          iconName: "DocumentSalesInvoiceAndLibraryFile",
          iconColor: "#1656FF",
        },
        {
          width: 230,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 530,
          type: EColumnType.SPE_TAG,
        },
        {
          width: 135,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 665,
          type: EColumnType.SPE_DATE,
          dateFormat: "emission",
        },
        {
          width: 135,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "end",
          shouldDisplay: cellsWorkableWidth! >= 800,
          type: EColumnType.SPE_DATE,
          dateFormat: "deposit",
        },
      ],
      associateCurrentAccount: [
        ...(isDefined(args?.[0]?.selectedCurrentPartner)
          ? [
              {
                width: 100,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                justifyContent: "center",
                shouldDisplay: true,
              },
              {
                width: 220,
                placeholderWidth: 204,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: true,
              },
              {
                width: 90,
                placeholderWidth: 80,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: true,
              },
              {
                width: 90,
                placeholderWidth: 80,
                placeholderType: EPlaceholderType.SIMPLE,
                justifyContent: "end",
                shouldDisplay: true,
              },
            ]
          : [
              {
                width: 345,
                placeholderWidth: 180,
                placeholderType: "squircleDouble",
                shouldDisplay: true,
              },
              {
                width: 155,
                placeholderWidth: 140,
                placeholderType: "double",
                justifyContent: "center",
                shouldDisplay: true,
              },
            ]),
      ],
      auxiliaryLedgerEntries: [
        {
          width: 115,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          justifyContent: "center",
          shouldDisplay: true,
          type: EColumnType.DATE,
          path: "dateValue",
          dateFormat: EDateFormat.DAY_MONTH_YEAR_SLASH,
          label: "Date",
        },
        {
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 430,
          type: EColumnType.SPE_TAG,
          label: "Nature",
          justifyContent: "center",
        },
        {
          width: 200,
          placeholderWidth: 150,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 540,
          type: EColumnType.TEXT,
          label: "Libellé",
          justifyContent: "center",
          path: "designation",
        },
        {
          width: 120,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 620,
          path: "debit",
          justifyContent: "center",
          type: EColumnType.SPE_AMOUNT,
          label: "Débit",
        },
        {
          width: 120,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 710,
          path: "credit",
          justifyContent: "center",
          type: EColumnType.SPE_AMOUNT,
          label: "Crédit",
        },
        {
          width: 230,
          placeholderWidth: 120,
          shouldDisplay: cellsWorkableWidth! >= 270,
          placeholderType: EPlaceholderType.EMPTY,
          justifyContent: "end",
          type: EColumnType.ACTION,
          label: "Action",
          path: "action",
        },
      ],
      vat: [
        {
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 150,
          type: EColumnType.SPE_TAG,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 150 : 300,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 250
            : cellsWorkableWidth! >= 400,
          type: EColumnType.SPE_DATE,
        },
        {
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 550
            : cellsWorkableWidth! >= 830,
          type: EColumnType.SPE_AMOUNT,
          label: "collectable",
        },
        {
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 660
            : cellsWorkableWidth! >= 940,
          type: EColumnType.SPE_AMOUNT,
          label: "deductible",
        },
        {
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 440
            : cellsWorkableWidth! >= 730,
          type: EColumnType.SPE_AMOUNT,
          label: "Others",
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 80 : 210,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 330
            : cellsWorkableWidth! >= 610,
          type: EColumnType.SPE_STATUS,
        },
      ],
      das2: [
        {
          width: 200,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.SPE_TAG,
        },
        {
          width: 100,
          shouldDisplay: cellsWorkableWidth! >= 300,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.DATE,
          path: "startDate",
          dateFormat: EDateFormat.FULL_YEAR,
        },
        {
          width: 200,
          shouldDisplay: cellsWorkableWidth! >= 500,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.MAIN,
        },
        {
          width: 200,
          shouldDisplay: cellsWorkableWidth! >= 700,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 80 : 210,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 780
            : cellsWorkableWidth! >= 910,
          type: EColumnType.SPE_STATUS,
        },
      ],
      companyTaxAdvances: [
        {
          width: 170,
          placeholderWidth: 170,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.SPE_TAG,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 90 : 200,
          shouldDisplay: cellsWorkableWidth! >= 300,
          placeholderWidth: 150,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_DATE,
        },
        {
          width: 150,
          shouldDisplay: cellsWorkableWidth! >= 700,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 80 : 210,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 780
            : cellsWorkableWidth! >= 910,
          type: EColumnType.SPE_TEXT,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 80 : 210,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 780
            : cellsWorkableWidth! >= 910,
          type: EColumnType.SPE_STATUS,
        },
      ],
      balanceSheet: [
        {
          width: 170,
          placeholderWidth: 160,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: true,
          type: EColumnType.SPE_TAG,
        },
        {
          width: args?.[0]?.isSidePanelOpen ? 150 : 300,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: args?.[0]?.isSidePanelOpen
            ? cellsWorkableWidth! >= 250
            : cellsWorkableWidth! >= 400,
          type: EColumnType.SPE_DATE,
        },
        {
          width: 210,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: true,
          type: EColumnType.SPE_STATUS,
        },
      ],
      payments: [
        {
          width: 200,
          shouldDisplay: cellsWorkableWidth! >= 860,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SQUIRCLE,
          type: EColumnType.MAIN,
        },
        {
          width: 50,
          shouldDisplay: cellsWorkableWidth! < 860,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SQUIRCLE,
          type: EColumnType.SPE_TEXT,
        },
        {
          width: 100,
          shouldDisplay: true,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_STATUS,
        },
        {
          width: 100,
          shouldDisplay: true,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_DATE,
        },
        {
          width: 200,
          shouldDisplay: true,
          placeholderWidth: 120,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TAG,
        },
        {
          width: 125,
          shouldDisplay: true,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.AMOUNT,
          path: "amount",
        },
        {
          width: 100,
          shouldDisplay: true,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          type: EColumnType.ACTION,
        },
      ],
      chartAccount: [
        {
          label: "Afficher",
          justifyContent: "center",
          width: 80,
          placeholderWidth: 80,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_STATUS,
          shouldDisplay: true,
        },
        {
          label: "N° de compte",
          justifyContent: "center",
          path: "number",
          width: 120,
          placeholderWidth: 110,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.MAIN,
          shouldDisplay: true,
        },
        {
          label: "Nom d'affichage catégorie",
          justifyContent: "center",
          width: 250,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SQUIRCLE,
          type: EColumnType.SPE_TAG,
          shouldDisplay: true,
        },
        {
          label: "Compte parent",
          justifyContent: "center",
          width: 250,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SQUIRCLE,
          type: EColumnType.SPE_TEXT,
          shouldDisplay: true,
          path: "name",
          emptyText: "",
        },
        {
          label: "Actions",
          justifyContent: "center",
          width: 115,
          placeholderWidth: 100,
          placeholderType: EPlaceholderType.SQUIRCLE_DOUBLE,
          type: EColumnType.ACTION,
          shouldDisplay: true,
        },
      ],
      preCompta: [
        {
          label: "Date",
          justifyContent: "left",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.MAIN,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "date",
        },
        {
          label: "Libellé",
          justifyContent: "left",
          width: 250,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "libellé",
        },
        {
          label: "Débit",
          justifyContent: "left",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "debit",
        },
        {
          label: "Crédit",
          justifyContent: "left",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "credit",
        },
        {
          label: "Solde cumulé",
          justifyContent: "left",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "intermediateSolde",
        },
        {
          label: "Différence",
          justifyContent: "left",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_AMOUNT,
          shouldDisplay: cellsWorkableWidth! >= 900,
          path: "difference",
        },
        {
          label: "Action",
          justifyContent: "center",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 180,
          path: "action",
        },
      ],
      balanceSheetDocuments: [
        {
          label: "Nom du document",
          justifyContent: "left",
          width: 500,
          placeholderWidth: 300,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.MAIN,
          shouldDisplay: true,
        },
        {
          label: "Action",
          justifyContent: "left",
          width: 300,
          placeholderWidth: 300,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: true,
        },
      ],
      specificAccountingPlan: [
        {
          label: "ID",
          justifyContent: "left",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.MAIN,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "id",
        },
        {
          label: "Nombre",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "number",
          flex: "start",
        },
        {
          label: "Libellé",
          justifyContent: "left",
          width: 350,
          placeholderWidth: 300,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "name",
          flex: "start",
        },
        {
          label: "Nature",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "nature.name",
          flex: "start",
        },
        {
          label: "Catégories",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "accountType.name",
          flex: "start",
        },
        {
          label: "Action",
          justifyContent: "center",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 180,
          path: "action",
        },
      ],
      persoAccountingPlan: [
        {
          label: "Nombre",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "number",
          flex: "start",
        },
        {
          label: "Libellé",
          justifyContent: "left",
          width: 350,
          placeholderWidth: 300,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "name",
          flex: "start",
        },
        {
          label: "Module",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TAG,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "lebelle",
        },
        {
          label: "Type de compta",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "comptaType",
        },
        {
          label: "Nature",
          justifyContent: "left",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
          path: "nature.name",
          flex: "start",
        },
        {
          label: "Action",
          justifyContent: "center",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 180,
          path: "action",
        },
      ],
      bankAccounts: [
        {
          label: "Identifiant",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "id",
          flex: "start",
        },
        {
          label: "Date de création",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 580,
          type: EColumnType.DATE,
          path: "createdAt",
          dateFormat: "DD/MM/YYYY",
        },
        {
          label: "Nom",
          width: 250,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TEXT,
          shouldDisplay: cellsWorkableWidth! >= 580,
        },
        {
          label: "Début import",
          justifyContent: "left",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_DATE,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "startImport",
        },
        {
          label: "Dernier import",
          justifyContent: "left",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_LAST_DATE,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "lastUpdate",
        },
        {
          label: "Plan comptable",
          width: 120,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 700,
          path: "account.number",
          flex: "start",
        },
        {
          label: "Agrégateur",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TAG,
          shouldDisplay: cellsWorkableWidth! >= 900,
        },
        {
          label: "État",
          width: 70,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_STATUS,
          shouldDisplay: cellsWorkableWidth! >= 900,
        },
        {
          label: "Action",
          justifyContent: "center",
          width: 150,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 180,
          path: "action",
        },
      ],
      bankSynchro: [
        {
          label: "Identifiant",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "id",
          flex: "start",
        },
        {
          label: "Date",
          justifyContent: "left",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 180,
          type: EColumnType.DATE,
          path: "lastUpdate",
          dateFormat: "DD/MM/YYYY",
        },
        {
          label: "Nom",
          width: 250,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 320,
          path: "name",
          flex: "start",
        },
        {
          label: "Budget ID",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 460,
          path: "idBudget",
          flex: "start",
        },
        {
          label: "Solde",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 460,
          path: "balance",
          flex: "start",
        },
        {
          label: "Désactivé le",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 580,
          type: EColumnType.DATE,
          path: "disabledAt",
          dateFormat: "DD/MM/YYYY",
        },
        {
          label: "État",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_STATUS,
          shouldDisplay: cellsWorkableWidth! >= 180,
        },
      ],
      genericAccount: [
        {
          label: "Nombre",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "number",
          flex: "start",
        },
        {
          label: "Nom",
          width: 250,
          placeholderWidth: 200,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.TEXT,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "name",
          flex: "start",
        },
        {
          label: "Types",
          width: 150,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_STATUS,
          shouldDisplay: cellsWorkableWidth! >= 0,
        },
        {
          label: "Secteurs",
          width: 200,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TEXT,
          shouldDisplay: cellsWorkableWidth! >= 0,
        },
        {
          label: "Natures",
          width: 120,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_TAG,
          shouldDisplay: cellsWorkableWidth! >= 0,
        },
        {
          label: "TVA défaut",
          width: 120,
          placeholderWidth: 130,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.VAT,
          path: "idVat",
          data: "full",
        },
        {
          label: "TVA forcée",
          width: 100,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_BOOLEAN,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "forceVat",
        },
        {
          label: "Facture forcée",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          type: EColumnType.SPE_BOOLEAN,
          shouldDisplay: cellsWorkableWidth! >= 0,
          path: "invoice",
        },
        {
          label: "Action",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.EMPTY,
          type: EColumnType.ACTION,
          shouldDisplay: cellsWorkableWidth! >= 180,
        },
      ],
      revisionAccount: [
        ...(args?.[0]?.isTPmodule
          ? [
              {
                label: "Id",
                width: 150,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 0,
                type: EColumnType.MAIN,
                path: "thirdParty.accountancyName",
              },
            ]
          : [
              {
                label: "Compte",
                width: 110,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 0,
                type: EColumnType.MAIN,
                path: "account.number",
                orderBy: "number",
              },
            ]),
        {
          label: "Intitulé",
          width: 50, // auto
          placeholderWidth: 40,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 480,
          type: EColumnType.SPE_TAG,
          path: args?.[0]?.isTPmodule ? "thirdParty.name" : "account.name",
          orderBy: "designation",
        },
        {
          label: "Solde N",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 810,
          type: EColumnType.AMOUNT,
          path: "balance",
          orderBy: "amount",
          useErrorColor: true,
        },
        {
          label: args?.[0]?.dateCompareText ?? "Période comparée",
          width: 120,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 930,
          type: EColumnType.AMOUNT,
          path: "comparedBalance",
        },
        {
          label: "Variation (€)",
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 590,
          type: EColumnType.SPE_AMOUNT,
          path: "",
          data: "euro",
        },
        {
          label: "Variation (%)",
          width: 110,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 700,
          type: EColumnType.SPE_AMOUNT,
          path: "",
          data: "percent",
        },
        {
          label: "Actions",
          width: 170,
          placeholderWidth: 150,
          placeholderType: EPlaceholderType.EMPTY,
          shouldDisplay: cellsWorkableWidth! >= 280,
          type: EColumnType.ACTION,
        },
      ],
      revisionAccountChild: [
        ...(args?.[0]?.isFilteringMode
          ? [
              {
                label: "Compte",
                width: 100,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 0,
                type: EColumnType.MAIN,
                path: "account.number",
                orderBy: "accountNumber",
              },
              {
                label: "Date",
                width: 100,
                placeholderWidth: 60,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 0,
                type: EColumnType.SPE_TAG, // default
                path: "dateValue",
                orderBy: "date",
              },
            ]
          : [
              {
                label: "Date",
                width: 120,
                placeholderWidth: 90,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 0,
                type: EColumnType.MAIN,
                path: "dateValue",
                orderBy: "date",
              },
            ]),
        {
          label: "Jal",
          tooltip: "Journal",
          width: 50,
          placeholderWidth: 30,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 945,
          type: EColumnType.TEXT,
          path: "accountingTransaction.accountingJournal.name",
        },
        {
          label: "Lettre",
          width: 60,
          placeholderWidth: 30,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 1010,
          type: EColumnType.TEXT,
          path: "letter",
        },
        {
          label: "Libellé",
          width: 200,
          placeholderWidth: 120,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 795,
          type: EColumnType.SPE_TEXT,
          path: "designation",
          orderBy: "designation",
        },
        {
          label: "TVA calculée",
          width: 115,
          placeholderWidth: 90,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 890,
          type: EColumnType.SPE_VAT,
          path: "calculatedVat",
          data: "vat",
        },
        {
          label: "Débit",
          width: 110,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 550,
          type: EColumnType.AMOUNT,
          path: "debit",
          orderBy: "debit",
          useErrorColor: true,
          hasSymbol: false,
        },
        {
          label: "Crédit",
          width: 110,
          placeholderWidth: 70,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 550,
          type: EColumnType.AMOUNT,
          path: "credit",
          orderBy: "credit",
          useErrorColor: true,
          hasSymbol: false,
        },
        ...(!args?.[0]?.isFilteringMode
          ? [
              {
                label: "Solde",
                width: 110,
                placeholderWidth: 70,
                placeholderType: EPlaceholderType.SIMPLE,
                shouldDisplay: cellsWorkableWidth! >= 550,
                type: EColumnType.AMOUNT,
                path: "balance",
              },
            ]
          : []),
        {
          label: "Actions",
          width: 170,
          placeholderWidth: 150,
          placeholderType: EPlaceholderType.EMPTY,
          shouldDisplay: cellsWorkableWidth! >= 280,
          type: EColumnType.ACTION,
        },
      ],
      expertAccountant: [
        {
          label: "#",
          width: 50,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
        {
          label: "Raison sociale",
          width: 300,
          placeholderWidth: 250,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 480,
          type: EColumnType.TEXT,
          path: "name",
          flex: "start",
        },
        {
          label: "Téléphone",
          width: 150,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 730,
          type: EColumnType.SPE_TEXT,
        },
        {
          label: "E-mail",
          width: 200,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 930,
          type: EColumnType.TEXT,
          path: "contact.email",
          flex: "start",
        },
        {
          label: "Montant",
          width: 270,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 1120,
          type: EColumnType.SPE_AMOUNT,
        },
        {
          label: "Action",
          width: 230,
          justifyContent: "center",
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 280,
          type: EColumnType.ACTION,
          path: "action",
        },
      ],
      expertAccountantActiveLine: [
        {
          label: "#",
          width: 1000,
          placeholderWidth: 50,
          placeholderType: EPlaceholderType.SIMPLE,
          shouldDisplay: cellsWorkableWidth! >= 0,
          type: EColumnType.MAIN,
        },
      ],
    };

    return itemLinesConfig;
  }

  return { getItemLineConfig };
}
